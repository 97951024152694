import React from 'react'
import Img from 'gatsby-image'
import { nanoid } from 'nanoid'

export default ({ articleimages, mobile, desktop, caption, size }) => {
  console.log('---------inlineimage--------')

  console.log('articleimages')
  console.log(articleimages)
  const elementId = nanoid(8)

  let unescapedMobile = mobile.replace('\\', '')
  unescapedMobile = mobile.replace('\\_', '_')
  let unescapedDesktop = desktop.replace('\\', '')
  unescapedDesktop = desktop.replace('\\_', '_')
  // unescapedName = name.replace('-', '')

  console.log('unescapedDesktop')
  console.log(unescapedDesktop)
  console.log('unescapedMobile')
  console.log(unescapedMobile)

  let articleimagesMobile = articleimages
    ? articleimages.filter((image) => {
        return image.relativePath.includes(unescapedMobile)
      })[0]
    : {}
  let articleimagesDesktop = articleimages
    ? articleimages.filter((image) => {
        return image.relativePath.includes(unescapedDesktop)
      })[0]
    : {}

  console.log('articleimagesMobile')
  console.log(articleimagesMobile)
  console.log('articleimagesDesktop')
  console.log(articleimagesDesktop)

  return (
    <div
      class={`container-inline-image d-flex flex-column align-items-center justify-content-center`}
    >
      <div
        class={`container-inline-image-inner ${
          size ? `image-inline-${size}` : 'w-100 h-100'
        }  border-1-gray-4 rounded-corners p-1 my-3 bg-gray-7`}
      >
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id={`pills-mobile-tab-${elementId}`}
              data-toggle="pill"
              href={`#pills-mobile-${elementId}`}
              role="tab"
              aria-controls={`pills-mobile-${elementId}`}
              aria-selected="true"
            >
              Mobile
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id={`pills-desktop-tab-${elementId}`}
              data-toggle="pill"
              href={`#pills-desktop-${elementId}`}
              role="tab"
              aria-controls={`pills-desktop-${elementId}`}
              aria-selected="false"
            >
              Desktop
            </a>
          </li>
        </ul>
        <div class="tab-content" id={`pills-tabContent-${elementId}`}>
          <div
            class="tab-pane fade show active text-center"
            id={`pills-mobile-${elementId}`}
            role="tabpanel"
            aria-labelledby={`pills-mobile-tab-${elementId}`}
          >
            {articleimagesMobile &&
            articleimagesMobile.childImageSharp &&
            articleimagesMobile.childImageSharp.fluid ? (
              <Img
                className={`rounded-corners w-100 h-100`}
                fluid={articleimagesMobile.childImageSharp.fluid}
              />
            ) : null}
            {articleimagesMobile &&
            articleimagesMobile.childImageSharp === null ? (
              <img
                class="unmanaged-img unmanaged-toggle-mobile"
                src={articleimagesMobile.publicURL}
              />
            ) : null}
          </div>
          <div
            class="tab-pane fade text-center"
            id={`pills-desktop-${elementId}`}
            role="tabpanel"
            aria-labelledby={`pills-desktop-tab-${elementId}`}
          >
            {articleimagesDesktop &&
            articleimagesDesktop.childImageSharp &&
            articleimagesDesktop.childImageSharp.fluid ? (
              <Img
                className={`rounded-corners w-100 h-100`}
                fluid={articleimagesDesktop.childImageSharp.fluid}
              />
            ) : null}

            {articleimagesDesktop &&
            articleimagesDesktop.childImageSharp === null ? (
              <img
                class="unmanaged-img unmanaged-toggle-desktop"
                src={articleimagesDesktop.publicURL}
              />
            ) : null}
          </div>
        </div>

        <div class="caption gray-1 line-height-1 pt-1 px-1 text-center text-lg-left">
          {caption}
        </div>
      </div>
    </div>
  )
}
