import React from 'react'
import Img from 'gatsby-image'

export default ({ number, col1, col2, col3 }) => {
  console.log('---------inlineColumns--------')

  return (
    <div
      class={`row container-inline-columns d-flex flex-column align-items-center justify-content-center`}
    >
      {number === '2' ? (
        <>
          <div class="col-md-6">{col1}</div>
          <div class="col-md-6">{col2}</div>
        </>
      ) : null}

      {number === '3' ? (
        <>
          <div class="col-md-4">{col1}</div>
          <div class="col-md-4">{col2}</div>
          <div class="col-md-4">{col3}</div>
        </>
      ) : null}
    </div>
  )
}
