import React from 'react'

const Snippet = ({ id }) => (
  <div>
    <iframe
      class="youtube-video"
      src={`https://www.youtube.com/embed/${id}?rel=0`}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  </div>
)

export default Snippet
