import React from 'react'
// import MastercraftTowerComparison from "./mastercraftTowerComparison"

const Snippet = ({ name }) => (
  <div>
    {/* {name === "MastercraftTowerComparison" ? (
      <MastercraftTowerComparison />
    ) : null} */}
  </div>
)

export default Snippet
